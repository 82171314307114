<template>
  <v-card class="mb-7 pb-7">
    <v-card-text class="pa-5 border-bottom">
      <h3 class="title blue-grey--text text--darken-2 font-weight-regular">Informacion de codigo</h3>
      <h6 class="subtitle-2 font-weight-light">{{ codeDetail.codigo }}</h6>
    </v-card-text>
    <v-form @submit.prevent="doEditCode" ref="form">
      <v-card-text>
        <div class="d-flex justify-space-between align-center px-6" :class="$vuetify.breakpoint.smAndDown ? 'flex-column': ''">
          <div :style="$vuetify.breakpoint.smAndDown ? 'width: 100%;': 'width: 45%;'">
            <p>Nombre de código</p>
            <v-text-field
              v-model="currentCode.codigo"
              dense
              outlined
              style="max-width: 495px;"
              append-icon="mdi-square-edit-outline"
              class="mx-auto"
              :rules="[rules.required, rules.maxLength(15)]"
              :error="errorMessages.currentCode.codigo!=''"
              :error-messages="errorMessages.currentCode.codigo"
              @focus="errorMessages.currentCode.codigo = ''"
            />
          </div>
          <div :style="$vuetify.breakpoint.smAndDown ? 'width: 100%;': 'width: 45%;'">
            <p>Descuento</p>
            <v-text-field
              v-model="currentCode.porcentaje_descuento"
              dense
              outlined
              style="max-width: 495px;"
              append-icon="mdi-square-edit-outline"
              class="mx-auto"
              :rules="[rules.required, rules.percentage(currentCode.porcentaje_descuento)]"
              :error="errorMessages.currentCode.porcentaje_descuento!=''"
              :error-messages="errorMessages.currentCode.porcentaje_descuento"
              @focus="errorMessages.currentCode.porcentaje_descuento = ''"
            />
          </div>
        </div>
      </v-card-text>
      <v-card flat width="92%" class="mx-auto mb-7 pb-5 mb-5 rounded-lg" style="border: 1px solid #DADEE3;">
        <v-card-text class="pa-5">
          <div class="d-md-flex align-start">
            <h3 class="title font-weight-regular">Matriculados con el código</h3>
            <v-spacer></v-spacer>
          </div>
        </v-card-text>
        <v-data-table
          :headers="headerArray"
          :items="listCodeDetail"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
          :items-per-page="10"
          class="mx-5"
        >
          <template v-slot:item.nombre="{ item }">
            <div class="d-flex" :class="$vuetify.breakpoint.smAndDown ? 'ml-5': ''">
              <div class="my-auto">
                <h4 class="font-weight-medium text-no-wrap">
                  {{ item.nombre }}
                </h4>
              </div>
            </div>
          </template>
          <template v-slot:item.pagado="{ item }">
            <div class="d-flex justify-end">
              <v-switch v-model="item.comision_pagada" @click="changeStatus(item)" class="pa-0"/>
            </div>
          </template>
        </v-data-table>
        <v-pagination
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </v-card>
      <div class="d-flex justify-end align-center mx-6">
        <v-btn class="mr-4 mr-2" color="#FFF" style="text-transform: none;">Eliminar</v-btn>
        <v-btn class="mr-4 mr-2" type="submit" :loading="loading" color="success" style="text-transform: none;">Guardar</v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import { maxLength, required, percentage } from '@/utils/rules.js'
import axios from 'axios'
import { mapActions, mapState } from 'vuex';

export default {
  data: () => ({
    guess: false,
    page: 1,
    pageCount: 0,
    show1: false,
    checkbox: "",
    loading: false,
    rules: {
      required,
      maxLength,
      percentage
    },
    errorMessages: {
      currentCode: {
        codigo: '',
        porcentaje_descuento: ''
      }
    },
    currentCode: {
      activo: false,
      cantidad_formularios: "",
      codigo: "",
      id: 0,
      porcentaje_descuento: null
    },
    headerArray: [
      { text: 'Nombre', align: 'start', sortable: false, value: 'nombre' },
      { text: 'Seccion', value: 'seccion', sortable: false, align: 'end' },
      { text: 'Fecha de Inscripción', value: 'inscripcion', sortable: false, align: 'end' },
      { text: 'Fecha de Matricula', value: 'matricula', sortable: false, align: 'end' },
      { text: 'Pago', align: 'center', sortable: false, value: 'monto_primer_pago' },
      { text: 'Pagado', align: 'center', sortable: false, value: 'pagado' }
    ],
    listCodeDetail: []
  }),
  computed: {
    ...mapState('codigos', ['codeDetail'])
  },
  methods: {
    ...mapActions('codigos', ['fetchCodeDetail']),
    async changeStatus (item) {
      try {
        console.log(item)
        await axios.post(`api/admin/matriculas/matriculas/${item.id}/update_comision_pagada/`, {
          comision_pagada: item.comision_pagada
        })
      } catch (e) {
        console.log(e)
      }
    },
    formatDate(date) {
      return new Date(date).toISOString().split('T')[0].replaceAll('-', '/')
    },
    async updateUser (userId, payload) {
      const userResponse = await axios.put(`api/admin/matriculas/codigos_referencia/${userId}/`, payload)
      this.currentUser = userResponse.data
    },
    async doEditCode () {
      this.loading = true
      try {
        this.currentCode.codigo = this.currentCode.codigo || null
        this.currentCode.porcentaje_descuento = this.currentCode.porcentaje_descuento || null
        await this.updateUser(this.currentCode.id,
          {
            activo: this.currentCode.activo,
            codigo: this.currentCode.codigo,
            porcentaje_descuento: this.currentCode.porcentaje_descuento
          }
        )
        await this.$root.$confirm({
          title: '¡Felicidades!',
          message: 'Se ha actualizado tu codigo de referencia.',
          acceptText: 'Aceptar'
        })
      } catch (e) {
        this.errorMessages.currentCode.codigo = e.response.data.codigo || ''
        this.errorMessages.currentCode.porcentaje_descuento = e.response.data.porcentaje_descuento || ''
        this.loading = false
        await this.$root.$confirm({
          title: '¡Lo sentimos!',
          message: 'Ha ocurrido un error actualizando su codigo. Intentelo nuevamente en unos minutos.',
          acceptText: 'Aceptar'
        })
      } finally {
        this.loading = false
      }
    }
  },
  async created () {
    await this.fetchCodeDetail(this.$route.params.id)
    const matriculados = await axios.get(`api/admin/matriculas/matriculas/?codigo=${this.codeDetail.codigo}`)
    this.currentCode = this.codeDetail
    this.listCodeDetail = matriculados.data.results.map(item => {
      return {
        id: item.id,
        nombre: item.usuario.first_name + ' ' + item.usuario.last_name,
        monto_primer_pago: item.monto_primer_pago,
        seccion: item.seccion.nombre,
        inscripcion: item.fecha_inscripcion? this.formatDate(item.fecha_inscripcion): '-',
        matricula: item.fecha_matricula? this.formatDate(item.fecha_matricula): '-',
        comision_pagada: item.comision_pagada,
        img: "/admin2.svg",
        pagado: false
      }
    })
    this.$emit('addBread')
  }
}
</script>
